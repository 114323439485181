import React, { useCallback, useContext, useMemo, useState } from "react";
import { Box, Dialog, DialogContent, TextField } from '@mui/material'
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { initDialogData } from "./initData";
import { generateMenuComboItem, loadMSData, validate } from "utils";
import PaperComponent from "components/PaperComponent";
import { useEffect } from "react";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { alertConfirmDelete, alertWarning } from "components/Alert";
import { poiApiNm, poiColNm } from "constants/POIConstant";
import GoogleMapWrapper from "components/GoogleMapWrapper/GoogleMapWrapper";
import TagBox from "components/TagBox";


let initMapCenter = null

const POIDialog = ({ selectedId, dialogOpen, setDialogOpen, onFinish, lastFilter }) => {

  const { ax, msData, setMSData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const [tagData, setTagData] = useState([])

  const latLngData = useMemo(() => {
    return {
      lat: dialogData.Lat,
      lng: dialogData.Lng,
      rad: dialogData.Rad,
      points: dialogData.points
    }
  }, [dialogData.Lat, dialogData.Lng, dialogData.Rad, dialogData.points])

  const setLatLngData = useCallback((latLngData) => {
    console.log("in setLatLngData latLngData::", latLngData)
    const { lat, lng, rad, points } = latLngData
    setDialogData((o) => ({ ...o, Lat: lat, Lng: lng, Rad: rad, points: points }))
  }, [])

  const validation = useMemo(() => ({
    POITypId: dialogData.POITypId === null,
    Name: dialogData.Name === "",
    SName: dialogData.SName === "",
  }), [dialogData])

  const textFieldProp = useCallback((name, isRequired) => ({
    variant: "outlined",
    size: "small",
    name: name,
    label: poiColNm[name],
    error: validation[name],
    value: dialogData[name] || "",
    required: isRequired,
    fullWidth: true,
    onChange: (e) => { setDialogData((o) => ({ ...o, [name]: e.target.value })) }
  }), [dialogData, setDialogData, validation])

  const insertUpdatePOI = useCallback((data) => {
    const apiName = selectedId === 0 ? poiApiNm.insertPOI : poiApiNm.updatePOI
    let polyStr = null
    if (dialogData.points && dialogData.points.length > 0) {
      const points = dialogData.points
      points.push(points[0])
      polyStr = `POLYGON((${dialogData.points.map(point => `${point.lat} ${point.lng}`).join(",")}))`
    }
    const postData = {
      ...dialogData,
      TagIds: tagData.map(tag => tag.id),
      PolyStr: polyStr,
      getArgs: lastFilter
    }
    ax.post(apiName, postData).then(value => {
      if (value.data) {
        setDialogOpen(false)
        if (onFinish) {
          onFinish(value.data)
        }
      }
    })
  }, [ax, lastFilter, dialogData, onFinish, selectedId, setDialogOpen, tagData])

  const deletePOI = useCallback((id) => {
    alertConfirmDelete(() => {
      ax.post(poiApiNm.deletepoi, { POIId: id, getArgs: lastFilter }).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
          loadMSData(ax, setMSData)
        }
      })
    })
  }, [ax, lastFilter, onFinish, setDialogOpen, setMSData])

  const handleDialogOk = useCallback(() => {

    if (validate(validation)) {
      insertUpdatePOI(dialogData)
    }
  }, [dialogData, insertUpdatePOI, validation])

  useEffect(() => {
    if (dialogOpen && selectedId > 0) {

      ax.post(poiApiNm.getPOI, { POIId: selectedId, withDetail: 1 }).then(value => {
        if (value.data && value.data.length > 0) {
          const data = value.data[0]

          if (data.PolyStr) {
            const points = data.PolyStr.replace("POLYGON((", "").replace("))", "").split(",").map(point => {
              const latLng = point.trim().split(" ")
              return { lat: +latLng[0], lng: +latLng[1] }
            })
            data.points = points
          }

          if (data.lat && data.lng) {
            initMapCenter = { lat: data.lat, lng: data.lng }
          } else if (data.points && data.points.length > 0) {
            const points = data.points
            // this code is generate from 
            const lat = points.reduce((acc, point) => acc + point.lat, 0) / points.length
            const lng = points.reduce((acc, point) => acc + point.lng, 0) / points.length
            initMapCenter = { lat, lng }
          }
          console.log("initMapCenter::", initMapCenter)

          setDialogData({ ...value.data[0] })
          if (value.data[0].TagIds) {
            setTagData(value.data[0].TagIds.map(tagId => ({ id: tagId, label: msData.tagObj[tagId] })))
          }
        } else {
          setDialogData({ ...initDialogData })
          alertWarning("ไม่พบข้อมูล")
        }
      })
    } else {
      initMapCenter = null
      setDialogData({ ...initDialogData })
      setTagData([])
    }
  }, [ax, dialogOpen, selectedId, msData.tagObj])
  // useEffect(() => {
  //   if (dialogOpen === false || !selectedId) {
  //     setIsEditReject(false)
  //     setDialogData({ ...initDialogData, Items: [{ ...initBookingItems }] })
  //   }
  // }, [dialogOpen, selectedId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="สถานที่"
        onDeleteClick={selectedId > 0 ? () => deletePOI(selectedId) : null}
      />
      <DialogContent>
        <BoxFR mt={2}>
          <BoxFC width={250}>
            <TextField {...textFieldProp("POITypId", true)} select >
              {generateMenuComboItem(msData.poiTypeCombo)}
            </TextField>
            <TextField {...textFieldProp("Name", true)} />
            <TextField {...textFieldProp("SName", true)} />
            <TextField {...textFieldProp("Tel")} />
            <TextField {...textFieldProp("Prty")} type="number" />
            <TextField {...textFieldProp("Addr")} multiline rows={5} />
            <TextField {...textFieldProp("Rmk")} multiline rows={5} />

            <TagBox value={tagData} setValue={setTagData} tblAbbr="LO" />
          </BoxFC>
          <Box alignSelf="stretch" flex={1}>
            <GoogleMapWrapper latLngData={latLngData} setLatLngData={setLatLngData} initCenter={initMapCenter} />
          </Box>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText={selectedId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(POIDialog)