import React, { useCallback, useMemo, useState, useEffect, useContext } from "react";
import { Box, Button, MenuItem, FormControlLabel, Checkbox, TextField, Paper } from "@mui/material";
import { initPageData as initJobOrderData, jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant";
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import ArticleIcon from '@mui/icons-material/Article';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { alertError, alertSuccess } from "components/Alert";
import { validate, containerNoCheck, getISOCd } from "utils";
import { printJobOrder } from "branch/functions/printJobOrder";
import dayjs from "dayjs";
import "resource/font/THSarabun-normal";
import { UserContext } from 'contexts/UserContext';
import CheckboxFormControl from "components/CheckboxFormControl";
import { BoxFR } from "components/BoxCustom";
import TagBox from "components/TagBox";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import { initRefColumnName } from "pages/Menu6/ServerDataManagement/constant";
import TextFieldChangeLanguage from "components/TextFieldChangeLanguage";
import ButtonGroupPopper from "components/ButtonGroupPopper";
import { modifyPOICombo } from "branch/functions/modifyPOICombo";
import ComboBox from "components/ComboBox";

let mShipmentData = null;

const JobOrderData = ({ ax, msData, shipmentData, prJobOrderData, isChangePrRtn, onFinish }) => {

  const [jobOrderData, setJobOrderData] = useState(initJobOrderData)
  const [tagData, setTatData] = useState([])
  const [numPrint, setNumPrint] = useState(1)
  const [isToUpper, setIsToUpper] = useState(true)
  const { user } = useContext(UserContext);
  const [isPrintDateTime, setIsPrintDateTime] = useState({ date: true, time: true })

  const refColumnName = msData.serverData.RefColumnName || initRefColumnName

  const refJobOrderColNm = useMemo(() => {
    const result = { ...jobOrderColNm }
    for (const name in refColumnName.job_order) {
      result[name] = refColumnName.job_order[name]
    }
    return result
  }, [refColumnName.job_order])

  const validation = useMemo(() => ({
    JobNo: jobOrderData.JobNo === "",
    ContSize: jobOrderData.ContSize === "",
    ContTyp: jobOrderData.ContTyp === "",
    ContHQ: jobOrderData.ContHq === "",
    TlrTypId: jobOrderData.TlrTypId === "",
    TakeDteSt: jobOrderData.TakeDteSt != null && !dayjs(jobOrderData.TakeDteSt).isValid(),
    TakeDteEn: jobOrderData.TakeDteEn != null && !dayjs(jobOrderData.TakeDteEn).isValid(),
    RtnDteSt: jobOrderData.RtnDteSt != null && !dayjs(jobOrderData.RtnDteSt).isValid(),
    RtnDteEn: jobOrderData.RtnDteEn != null && !dayjs(jobOrderData.RtnDteEn).isValid(),
  }), [jobOrderData])

  const dataProp = (name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    label: refJobOrderColNm[name],
    fullWidth: true,
    error: validation[name],
  })
  const jobOrderControledDataProp = (name) => ({
    ...dataProp(name),
    value: jobOrderData[name],
    onChange: (e) => { setJobOrderData((oldData) => ({ ...oldData, [name]: e.target.value })) }
  })
  const jobOrderDataProp = (name) => ({
    ...dataProp(name),
    defaultValue: jobOrderData[name],
    onBlur: (e) => {
      if (isToUpper === true) e.target.value = e.target.value.toUpperCase();
      if (name === "ContNo") {
        containerNoCheck(e.target.value);
      }
      setJobOrderData((oldData) => ({ ...oldData, [name]: e.target.value }))
    }
  })

  const jobDataProp = (name) => ({
    ...dataProp(name),
    defaultValue: jobOrderData.Job ? jobOrderData.Job[name] : "",
    // onBlur: (e) => { setJobData((oldData) => ({ ...oldData, [name]: e.target.value })) }
  })

  const datePickerJobOrderProp = useCallback((name, isRequire) => ({
    label: jobOrderColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: jobOrderData[name],
    onChange: (newValue) => { setJobOrderData(oldState => ({ ...oldState, [name]: newValue })) },
    renderInput: (params) => <TextField size="small"  {...params} required={isRequire} error={validation[name]} fullWidth />,
  }), [jobOrderData, validation])

  const generateMenuItem = useCallback((list) => (
    list.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.label}
      </MenuItem>
    ))
  ), [])

  const handleAddJobOrder = useCallback(async () => {
    console.log("-------------------- handleAddJobOrder print---------------------")
    if (!validate(validation)) {
      return null;
    }
    if (jobOrderData.ShpmId === 0) {
      alertError("กรุณาเลือกใบแจ้งลากก่อน");
      return null
    }
    jobOrderData.ISOCd = getISOCd(jobOrderData.ContSize, jobOrderData.ContTyp, jobOrderData.ContHq)
    // console.log("jobOrderData.ISOCd::", jobOrderData.ISOCd)
    const numInt = parseInt(numPrint);

    console.log("numInt::", numInt)
    if (isNaN(numInt)) {
      alertError("จำนวนใบงานเป็นตัวเลขเท่านั้น")
      return null
    }
    if (numInt <= 0) {
      alertError("จำนวนใบงานต้องมากกว่า 0")
      return null
    }

    const postData = { 
      ...jobOrderData, 
      TagIds: tagData.map(item => item.id), 
      NumAdd: numInt
    }
    delete postData.ModAcc
    delete postData.Job
    delete postData.Cus
    const result = await ax.post(jobOrderApiNm.insertJobOrder, postData)
    let jobOrdIds = null
    if (result) {
      jobOrdIds = result.data
    }
    if (!result.data) {
      return;
    }
    if (isChangePrRtn) {
      const jsonData = {
        JobOrdId: jobOrderData.PrId,
        ColValues: {
          RtnPlc: "ตัดหาง",
          RtnPOIId: 2
        },
        LogRmk: "เพิ่มใบงานย่อย ตัดหางใบงานหลัก"
      }
      await ax.post(jobOrderApiNm.updateJobOrderColumn, jsonData)
    }
    if(onFinish) onFinish()

    alertSuccess("เพิ่มใบงานเรียบร้อยแล้ว");
    
    return jobOrdIds
  }, [ax, jobOrderData, validation, numPrint, isChangePrRtn, tagData, onFinish])


  const handlePrint = useCallback(async (isPreview, withBackground) => {
    console.log("-------------------- handle print---------------------")
    const numInt = parseInt(numPrint);

    // if (jobOrderData.AptTm === null) {
    //   alertError("กรุณาใส่วันที่เข้าโรงงานก่อน")
    //   return
    // }

    console.log("numInt::", numInt)
    if (isNaN(numInt)) {
      alertError("จำนวนใบงานเป็นตัวเลขเท่านั้น")
      return
    }
    if (numInt <= 0) {
      alertError("จำนวนใบงานต้องมากกว่า 0")
      return
    }
    if (isPreview === true) {
      let dummyJobOrdIDs = []
      for (let i = 0; i < numPrint; i++) {
        dummyJobOrdIDs.push(0)
      }
      const printDataArr = [{ jobOrderData, shipmentData: mShipmentData, jobOrdIds: dummyJobOrdIDs, fName: user.FName, isPrintDateTime }]
      printJobOrder({ printDataArr, msData, withBackground: true })
      return;
    }

    const jobOrdIds = await handleAddJobOrder()
    if (jobOrdIds) {
      console.log("handlePrint jobOrdIds", jobOrdIds)
      const printDataArr = [{ jobOrderData, shipmentData: mShipmentData, jobOrdIds, fName: user.FName, isPrintDateTime }]
      printJobOrder({ printDataArr, msData, withBackground })
    }

  }, [jobOrderData, numPrint, user.FName, handleAddJobOrder, msData, isPrintDateTime])

  const handleIsPrintDateTimeChange = useCallback((name, checked) => {
    if (name === "date" && !checked) {
      setIsPrintDateTime({ date: false, time: false })
    } else if (name === "time" && checked) {
      setIsPrintDateTime({ date: true, time: true })
    } else {
      setIsPrintDateTime(o => ({ ...o, [name]: checked }))
    }
  }, [])
  useEffect(() => {
    console.log(" prJobOrderData::", prJobOrderData)
    console.log(" shipmentData::", shipmentData)
    if (shipmentData && shipmentData.ShpmId > 0) {
      mShipmentData = shipmentData;
      setJobOrderData(oldData => ({
        ...oldData,
        JobNo: shipmentData.JobNo,
        // TakePlc: shipmentData.TakePlc,
        // RtnPlc: shipmentData.RtnPlc,
        TakePOIId: shipmentData.TakePOIId,
        RtnPOIId: shipmentData.RtnPOIId,
        TakeDteSt: shipmentData.TakeDteSt && dayjs(shipmentData.TakeDteSt).format("YYYY-MM-DD HH:mm:00.000"),
        TakeDteEn: shipmentData.TakeDteEn && dayjs(shipmentData.TakeDteEn).format("YYYY-MM-DD HH:mm:00.000"),
        RtnDteSt: shipmentData.RtnDteSt && dayjs(shipmentData.RtnDteSt).format("YYYY-MM-DD HH:mm:00.000"),
        RtnDteEn: shipmentData.RtnDteEn && dayjs(shipmentData.RtnDteEn).format("YYYY-MM-DD HH:mm:00.000"),
        // Loc: shipmentData.Job.Loc,
        LocPOIId: shipmentData.LocPOIId,
        JobId: shipmentData.JobId,
        ShpmId: shipmentData.ShpmId,
        Job: shipmentData.Job
      }))
    } else if (prJobOrderData && prJobOrderData.JobOrdId > 0) {
      mShipmentData = prJobOrderData.Shipment
      
      setJobOrderData({
        ...prJobOrderData,
        JobOrdId: 0,
        PlnTxt: "",
        PlnClr: "",
        ArvTm: null,
        LevTm: null,
        AptTm: null,
        DrvId: null,
        DrvActTm: null,
        DrvRmk: null,
        TukId: null,
        TakeArvTm: null,
        TakeLevTm: null,
        RtnArvTm: null,
        RtnLevTm: null,
        PrId: prJobOrderData.JobOrdId,
        Job: prJobOrderData.Shipment.Job, 
        Memo: null
      })
    }
  }, [shipmentData, prJobOrderData, isChangePrRtn])

  console.log("Render JobOrderData jobOrderData::", jobOrderData)
  return (
    <Box display="flex" flexGrow={1} mt={2} flexDirection="column" gap={1} key={[jobOrderData.ShpmId, jobOrderData.TakePlc, jobOrderData.TakePOIId, jobOrderData.Rmk]}>

      <Box display="flex" flexDirection="row" gap={2}>
        <FormControlLabel sx={{ m: 0, flex: 1 }}
          label="ใช้อักษรพิมพ์ใหญ่(ภาษาอังกฤษ)"
          control={<Checkbox sx={{ p: 0 }} checked={isToUpper} onChange={(e) => setIsToUpper(e.target.checked)} />}
        />
        <BoxFR flex={1}>
          <CheckboxFormControl label="พิมพ์วันที่" checked={isPrintDateTime.date} onChange={(e) => handleIsPrintDateTimeChange("date", e.target.checked)} />
          <CheckboxFormControl label="พิมพ์เวลา" checked={isPrintDateTime.time} onChange={(e) => handleIsPrintDateTimeChange("time", e.target.checked)} />
        </BoxFR>

      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <TextFieldChangeLanguage {...jobOrderDataProp("JobNo")} required disabled />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("AptTm", false)} />
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <TextFieldChangeLanguage {...jobOrderDataProp("ContNo")} />
        <TextFieldChangeLanguage {...jobOrderDataProp("SealNo")} />
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <TextField select {...jobOrderControledDataProp("ContSize")} required >{generateMenuItem(msData.contSizeCombo)}</TextField>
        <TextField select {...jobOrderControledDataProp("ContTyp")} required >{generateMenuItem(msData.contTypCombo)}</TextField>
        <TextField select {...jobOrderControledDataProp("ContHq")} required >
          <MenuItem value={0}>Std</MenuItem>
          <MenuItem value={1}>HQ</MenuItem>
        </TextField>
        <TextField select {...jobOrderControledDataProp("TlrTypId")} required >{generateMenuItem(msData.tlrTypCombo)}</TextField>
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>

        <ComboBox options={modifyPOICombo(msData.poiCombo, "TAKE", shipmentData?.ShpmTypId)}
          sx={{ width: "100%" }}
          label={jobOrderColNm.TakePlc}
          selectedId={jobOrderData.TakePOIId}
          setSelectedId={(id) => setJobOrderData(o => ({ ...o, TakePOIId: id }))} />
        <ComboBox options={modifyPOICombo(msData.poiCombo, "LOC", shipmentData?.ShpmTypId)}
          sx={{ width: "100%" }}
          label={jobOrderColNm.Loc}
          selectedId={jobOrderData.LocPOIId}
          setSelectedId={(id) => setJobOrderData(o => ({ ...o, LocPOIId: id }))} />
        <ComboBox options={modifyPOICombo(msData.poiCombo, "RTN", shipmentData?.ShpmTypId)}
          sx={{ width: "100%" }}
          label={jobOrderColNm.RtnPlc}
          selectedId={jobOrderData.RtnPOIId}
          setSelectedId={(id) => setJobOrderData(o => ({ ...o, RtnPOIId: id }))} />
        {/* <TextFieldChangeLanguage {...jobOrderDataProp("TakePlc")} />
        <TextFieldChangeLanguage {...jobOrderDataProp("Loc")} />
        <TextFieldChangeLanguage {...jobOrderDataProp("RtnPlc")} /> */}
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeDteSt", false)} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeDteEn", false)} />
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <DateTimePickerCustomTime {...datePickerJobOrderProp("RtnDteSt", false)} />
        <DateTimePickerCustomTime {...datePickerJobOrderProp("RtnDteEn", false)} />
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <TextFieldChangeLanguage {...jobOrderDataProp("Ref1")} />
        <TextFieldChangeLanguage {...jobOrderDataProp("Ref2")} />
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <TextFieldChangeLanguage {...jobDataProp("RcptNm")} />
        <TextFieldChangeLanguage {...jobDataProp("RcptTaxId")} sx={{ width: 300 }} />
      </Box>
      <TextFieldChangeLanguage {...jobDataProp("RcptAddr")} multiline rows={3} />
      <TextFieldChangeLanguage {...jobOrderDataProp("Rmk")} />
      <TagBox value={tagData} setValue={setTatData} tblAbbr="JO" />

      <Box display="flex" flexDirection="row" gap={2} justifyContent="flex-end">
        <TextFieldChangeLanguage label="จำนวนใบงาน" size="small" sx={{ width: 100 }} inputProps={{ style: { textAlign: "center" } }}
          value={numPrint} onChange={e => setNumPrint(e.target.value)} />
        <Button startIcon={<ArticleIcon />} size="small" variant="contained" onClick={() => handlePrint(true, true)}>Preview</Button>
        <ButtonGroupPopper mainButton={
          <Button startIcon={<PrintRoundedIcon />}
            size="small" variant="contained"
            onClick={() => handlePrint(false, false)}>
            เพิ่มและพิมพ์ใบงาน
          </Button>
        }
          arrowButtonStyle={{ variant: "contained", color: "primary" }}
        >
          <Paper>
            <Button size="small" variant="outlined" onClick={() => handlePrint(false, true)}>พิ้มพ์พร้อมพื้นหลัง</Button>
          </Paper>

        </ButtonGroupPopper>

        <Button startIcon={<AddCircleRoundedIcon />} size="small" variant="contained" onClick={handleAddJobOrder}>เพิ่มใบงาน</Button>
      </Box>
    </Box>
  )
}

export default JobOrderData