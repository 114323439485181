import { Button, ButtonGroup, Dialog, DialogContent } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import PayComponent from "./PayComponent"
import { initAdvanceDialogData } from "./initData"
import { UserContext } from "contexts/UserContext"
import { pettyCashApiNm } from "./constant"
import { alertError } from "components/Alert"
import dayjs from "dayjs"

const AdvanceDialog = ({ pcTrnsId, pcTrnsData, dialogOpen, setDialogOpen, onFinish, isFromJobOrder = false, joData
  , usrAccId, getFn, getArgs }) => {
  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initAdvanceDialogData })

  const initAdvanceDialogDataMemo = useMemo(() => ({
    ...initAdvanceDialogData,
    PCJnlId: msData.pcJournalCombo.find(item => item.IsDf)?.id || null
  })
    , [msData.pcJournalCombo])

  const handleDialogOK = useCallback(() => {

    if (!dialogData.PayTm || !dialogData.Amnt || !dialogData.PCJnlId) {
      alertError("กรุณากรอกข้อมูลให้ครบถ้วน")
      return
    }
    if (!dialogData.JobOrdId && !dialogData.UsrAccId) {
      alertError("กรุณากรอกเลขที่ใบงาน หรือ ผู้รับเงิน")
      return
    }

    if (dialogData.isJobOrdIdCorrect === false) {
      alertError("เลขที่ใบงานไม่ถูกต้อง")
      return
    }

    if (!isFromJobOrder) {
      const postData = {
        ...dialogData,
        AdmAccId: user.AccId,
        getFn: getFn || "getPettyCashDetail",
        getArgs: getArgs
      }
      ax.post(pcTrnsId ? pettyCashApiNm.updatePCTransaction : pettyCashApiNm.insertPCTransaction, postData).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    } else {
      onFinish({
        ...dialogData,
        AdmAccId: user.AccId,
        ExpNm: msData.expObj[dialogData.ExpId]?.ExpNm || "",
        UsrNNm: msData.accountAllCombo.find(acc => acc.id === dialogData.UsrAccId)?.label || ""
      })
      setDialogOpen(false)
    }
  }, [ax, dialogData, onFinish, setDialogOpen, user.AccId, pcTrnsId, isFromJobOrder, msData.accountAllCombo
    , msData.expObj, getArgs, getFn])

  const handleDelete = useCallback(() => {
    onFinish(pcTrnsData, "DEL")
    setDialogOpen(false)
  }, [pcTrnsData, onFinish, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      if (pcTrnsId) {
        ax.post(pettyCashApiNm.getPettyCashTable, { PCTrnsIdExat: pcTrnsId }).then(value => {
          if (value.data && value.data.length === 1) {
            const data = value.data[0]
            setDialogData({
              ...data,
              LocText: `(${data.ContSizeFull || ""})${data.TakePlc || ""}-${data.Loc || ""}-${data.RtnPlc || ""}`,
              isJobOrdIdCorrect: data.JobOrdId ? true : null,
            })
          }
        })
      } else if (joData) {
        const takePlc = msData.poiObj[joData.TakePOIId] || ""
        const loc = msData.poiObj[joData.LocPOIId] || ""
        const rtnPlc = msData.poiObj[joData.RtnPOIId] || ""
        if (pcTrnsData) {
          setDialogData({
            ...pcTrnsData,
            PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
          })
        } else {
          setDialogData({
            ...initAdvanceDialogDataMemo,
            JobOrdId: joData.JobOrdId,
            PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
            UsrAccId: usrAccId,
            LocText: `(${joData.ContSizeFull || ""})${takePlc}-${loc}-${rtnPlc}`,
            isJobOrdIdCorrect: true,
          })
        }
      } else {
        setDialogData({
          ...initAdvanceDialogDataMemo,
          PayTm: dayjs().format("YYYY-MM-DD HH:mm"),
          UsrAccId: usrAccId,
        })
      }

    } else {
      setDialogData({ ...initAdvanceDialogDataMemo })
    }
  }, [dialogOpen, ax, pcTrnsId, initAdvanceDialogDataMemo, isFromJobOrder, joData, usrAccId, msData.poiObj, pcTrnsData])

  console.log('AdvanceDialog', dialogData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={dialogData.PrPCTrnId ? "เงินทอน-เงินเพิ่ม" : "การจ่ายเงิน-รับเงิน"}
        onDeleteClick={isFromJobOrder && joData ? handleDelete : null}
      />
      <DialogContent>
        <BoxFC pt={2}>
          <BoxFR width="100%" justifyContent="center">
            <ButtonGroup sx={{width: "100%"}}>
              <Button fullWidth variant={dialogData.InOutTyp === "O" ? "contained" : "outlined"}
                onClick={() => { setDialogData(o => ({ ...o, InOutTyp: "O", IsCost: 1 })) }}>จ่ายเงิน</Button>
              <Button fullWidth variant={dialogData.InOutTyp === "I" ? "contained" : "outlined"}
                onClick={() => { setDialogData(o => ({ ...o, InOutTyp: "I", IsCost: 1 })) }}>รับเงิน</Button>
            </ButtonGroup>
          </BoxFR>
          <PayComponent dialogData={dialogData} setDialogData={setDialogData} usrAccId={usrAccId} jobOrdId={joData?.JobOrdId} />
        </BoxFC>

      </DialogContent>
      <DialogFooter okText={isFromJobOrder ? "เพิ่มข้อมูล" : "บันทึกข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default React.memo(AdvanceDialog)