

export const branchApiNm = {
  getJobOrderAllDetail: "/branch/getJobOrderAllDetail"
}
export const extraPageName = {}
export const extraPageMenu = []

export const extraRoute = (path)=> []

export const extraMainRoute = (path)=> []
