import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const cusApiNm = {
  getCustomer: "/cus-job/getCustomer",
  insertCustomer: "/cus-job/insertCustomer",
  updateCustomer: "/cus-job/updateCustomer",
  deleteCustomer: "/cus-job/deleteCustomer",
  uploadCustomerFile: "/cus-job/uploadCustomerFile",
  updateCustomerFileName: "/cus-job/updateCustomerFileName",
  deleteCustomerFile: "/cus-job/deleteCustomerFile",
}

export const cusColNm =  {
  CusId: "รหัสลูกค้า",
  Name: "ชื่อลูกค้า",
  SName: "ชื่อย่อ",
  Abbr: "ตัวย่อ", 
  Addr: "ที่อยู่",
  Addr2: "ที่อยู่2",
  Addr3: "ที่อยู่3",
  TaxId: "เลขประจำตัวผู้เสียภาษี",
  Rmk: "หมายเหตุ",
  ModDte: "แก้ไขล่าสุด",
  ModAcc: "ผู้แก้ไขล่าสุด",
  CtDscp: "รายละเอียดผู้ติดต่อ",
  CtMailCont: "Email ส่งเบอร์ตู้",
  CtMailInv: "Email ส่งเอกสารบัญชี",
  XpssCusCod: "รหัลูกค้า Express",
  IsActv: "Active"
}


export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "IsActv",
    headerName: "Active",
    width: 100,
    align: "center",
    renderCell: (params) => {
      return params.value===1?<CheckCircleIcon color="success"/>: <CancelIcon color="action"/>
    },
    // resizable: true
  },
  {
    field: "Name",
    headerName: cusColNm.Name,
    width: 300,
    // resizable: true
  },
  {
    field: "SName",
    headerName: cusColNm.SName,
    width: 200,
    // resizable: true
  },
  {
    field: "Abbr",
    headerName: cusColNm.Abbr,
    width: 80,
    // resizable: true
  },
  {
    field: "TaxId",
    headerName: cusColNm.TaxId,
    width: 170,
    // resizable: true
  },
  {
    field: "Addr",
    headerName: cusColNm.Addr,
    flex: 1,
    // resizable: true
  },
  {
    field: "NumFile",
    headerName: "Files",
    width: 80,
  },
  {
    field: "XpssCusCod",
    headerName: cusColNm.XpssCusCod,
    width: 80,
  },
  {
    field: "Rmk",
    headerName: cusColNm.Rmk,
    width: 300,
    // resizable: true
  }
]

export const initDialogData = {
  CusId: 0,
  Name: "",
  SName: "",
  Abbr:"",
  Addr: "",
  Addr2: "",
  Addr3: "",
  TaxId: "",
  BrchNo: 0,
  IsHeadBrch: 1,
  CtDscp: "",
  CtMailCont: "",
  CtMailInv: "",
  Rmk: "",
  ModDte: null,
  ModAccId: 0,
  ModAcc: null,
  XpssCusCod: "", 
  IsActv: 1
}

export const initFilterData = {
  Name: null,
  SName: null,
}

export const act = {
  SET_IS_LOADING: "SET_IS_LOADING",
  ON_CHANGE_FILTER: "ON_CHANGE_FILTER",
  ON_CHANGE_DIALOG_DATA: "ON_CHANGE_DIALOG_DATA",
  CLEAR_FILTER: "FILTER_CLEAR",
  SET_DATA_TABALE: "SET_DATA_TABALE",
  SET_DIALOG_OPEN: "SET_DIALOG_OPEN",
  SET_DIALOG_DATA: "SET_DIALOG_DATA"
}