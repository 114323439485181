import dayjs from "dayjs"
import { jsPDF } from "jspdf";

import jobOrderbg from "branch/resource/job-order-bg.png"
import { getPOIPlace } from "utils/getPOIPlace";
// export const printJobOrder = ({ printDataArr, msData, withBackground }) => {

//   console.log("printDataArr::", printDataArr)
//   let img = null
//   if(withBackground){
//     console.log("in withBackground");
//     img = new Image()
//     img.src = jobOrderbg
//     img.onload = function(){
//       console.log("in onload");
//     }
//     printJobOrderLocal({ printDataArr, msData, bgImg: img })
//   }
//   else {
//     printJobOrderLocal({ printDataArr, msData, bgImg: null })
//   }
// }

export const printJobOrder = ({ printDataArr, msData, withBackground }) => {

  const pageSize = [9, 5.5];
  const offsetY = -0.02375;
  const colBarcode = 7.2 // middle
  const col1 = 1.9;
  const col2 = 4.28125;
  const col3 = 7;
  const colAppointD = 5.46875; //5 -15/32
  const colClosing = 6;
  const colExport = 2;
  const colImport = 3.3125; //3 - 10/32
  const colLashing = 4.625; //4 - 20/32
  const colReceiptName = 2.5
  const colReceiptAddr = 1.125 //1 - 4/32
  const colAccount = 3.5 // middle
  const col20 = 5.6875; //5 - 22/32
  const col40 = 6.71875; //6 - 23/32
  const col45 = 7.75; //7 - 24/32

  const lineBarcode = 0.75 + offsetY
  const line1 = 1.46875 + offsetY; // 1 - 15/32
  const line2 = 1.6875 + offsetY; // 1 - 22/32
  const line3 = 1.9375 + offsetY; // 1 - 30/32
  const line4 = 2.3125 + offsetY; // 2 - 10/32
  const line5 = 2.6875 + offsetY // 2- 22/32
  const line6 = 2.96875 + offsetY // 3 - 32/32 x mark middle
  const line7 = 3.48 + offsetY // 3 - 15/32
  // const line8 = 3.71875 + offsetY // 3 - 23/32
  const line9 = 3.9375 + offsetY // 3 - 30/32
  const line10 = 4.2375 + offsetY // 4 - 6/32
  const lineRcptAddr = 4.40375 + offsetY // 4 - 11/32
  const line11 = 5 + offsetY // 5 - 32/32

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: pageSize
  });

  let j = 0;
  for (const { jobOrderData, shipmentData, jobOrdIds, fName, isPrintDateTime } of printDataArr) {

    let remarkTxt = jobOrderData.Rmk;
    if (jobOrderData.RtnDteSt) {
      remarkTxt = `คืนได้วันแรก: ${dayjs(jobOrderData.RtnDteSt).format("DD/MM HH:mm")}`
      if (jobOrderData.Rmk) {
        remarkTxt += `, ${jobOrderData.Rmk}`
      }
    }
    let date = jobOrderData.AptTm ? dayjs(jobOrderData.AptTm).format("DD    MM    YYYY") : ""
    let time = jobOrderData.AptTm ? dayjs(jobOrderData.AptTm).format("HH:mm") : ""
    if (isPrintDateTime) {
      if (!isPrintDateTime.date) {
        date = ""
      }
      if (!isPrintDateTime.time) {
        time = ""
      }
    }

    const objs = [
      { text: date, x: colAppointD, y: line1 },
      { text: time, x: col3, y: line1 },
      { text: shipmentData.CusNm, x: col1, y: line2 },
      { text: jobOrderData.JobNo, x: col3, y: line2 },
      { text: getPOIPlace(jobOrderData, "TAKE"), x: col1, y: line3 },
      { text: getPOIPlace(jobOrderData, "LOC"), x: col2, y: line3 },
      { text: getPOIPlace(jobOrderData, "RTN"), x: col3, y: line3 },
      { text: shipmentData.Bkg, x: col1, y: line4 },
      { text: shipmentData.Agnt, x: col2, y: line4 },
      { text: shipmentData.Port, x: col3, y: line4 },
      { text: `${shipmentData.Vssl} V.${shipmentData.Voy}`, x: col1, y: line5 },
      { text: (jobOrderData.RtnDteEn && dayjs(jobOrderData.RtnDteEn).format("DD/MM/YYYY  เวลา HH:mm")) || "", x: colClosing, y: line5 },
      { text: jobOrderData.ContNo, x: col1, y: line7 },
      { text: jobOrderData.SealNo, x: col2, y: line7 },
      { text: remarkTxt, x: col1, y: line9 },
      { text: `${shipmentData.Job.RcptNm} TAX ID: ${shipmentData.Job.RcptTaxId}`, x: colReceiptName, y: line10 },
      { text: doc.splitTextToSize(shipmentData.Job.RcptAddr, 12).join("\n"), x: colReceiptAddr, y: lineRcptAddr },
    ]
    doc.setFont("THSarabun")
    let i = 0;
    do {
      if (i + j !== 0) {
        doc.addPage(pageSize, "landscape")
      }

      if (withBackground) {
        try {
          const img = new Image()
          img.src = jobOrderbg
          doc.addImage(img, 'png', 0.6, -0.03, pageSize[0] - 1.2, pageSize[1] + 0.06, "xx", "SLOW")
        } catch (e) {
          console.log("error::", e)
        }
      }
      doc.setFontSize(20)
      doc.text(jobOrdIds[i].toString(), colBarcode, lineBarcode, { align: "center" })
      let colx = 0;
      if (shipmentData.ShpmTypId === 1) colx = colExport;
      else if (shipmentData.ShpmTypId === 2) colx = colImport;
      else colx = colLashing;
      doc.text("X", colx, line6, { align: "center", baseline: "middle" })

      if (jobOrderData.ContSize === 'บรรทุก') colx = colLashing;
      else if (jobOrderData.ContSize === '20') colx = col20;
      else if (jobOrderData.ContSize === '40') colx = col40;
      else colx = col45
      doc.text("X", colx, line6, { align: "center", baseline: "middle" })

      doc.setFontSize(15)
      if (jobOrderData.ContSize !== 'บรรทุก') {
        let text = "";
        if (jobOrderData.ContTyp === "GP") {
          if (jobOrderData.ContHq === 1) text = "HQ"
          else text = "GP"
        } else {
          text = jobOrderData.ContTyp
          if (jobOrderData.ContHq === 1) text += "HQ"
        }
        doc.text(text, colx - 0.4063, line6 + 0.06)
      }
      for (const obj of objs) {
        console.log("obj::", obj)
        doc.text(obj.text, obj.x, obj.y);
      }
      doc.text(fName, colAccount, line11, { align: "center" })
      i++;
      j++;
    } while (i < jobOrdIds.length)
  }

  // var options = {
  //   pageSize: 'A4',
  //   margin: {
  //     top: 20,
  //     right: 20,
  //     bottom: 20,
  //     left: 20
  //   }
  // };
  // doc.html("<h1>helloooooooooooooooo</h1>", options)
  //   doc.html("<html><body><h1>ssdfsdfsf</h1></body></html>", {
  //     callback: function (doc) {
  //       console.log("inCallback:: doc::", doc)
  //       // doc.save();
  //   doc.output('dataurlnewwindow');
  //     },
  //     x: 10,
  //     y: 10
  //  });
  // doc.output('datauri');
  doc.output('dataurlnewwindow');
  // window.open(doc.autoPrint() , '_blank')
  // doc.autoPrint({variant: 'non-conform'});
  // window.open(doc.output('bloburl'), '_blank')
}