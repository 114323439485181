
export const poiApiNm = {
  getPOI: "/poi/getPOI",
  getPOIInBond: "/poi/getPOIInBond",
  insertPOI: "/poi/insertPOI",
  updatePOI: "/poi/updatePOI",
  deletepoi: "/poi/deletepoi",
  fetchPOIFromDTC: "/poi/fetchPOIFromDTC",
}

export const poiColNm =  {
  Name: "ชื่อสถานที่",
  SName: "ชื่อย่อ",
  Addr: "ที่อยู่",
  Tel: "เบอร์โทรศัพท์",
  Lat: "ละติจูด",
  Lng: "ลองจิจูด",
  POITypId: "ประเภทสถานที่",
  Rad: "รัศมี",
  PoiTypNm: "ประเภทสถานที่",
  Rmk: "หมายเหตุ",
  Prty: "Priority",
}