import { RemoveCircleRounded, PriceCheck, Receipt, EditRounded, PublishedWithChangesRounded, InfoRounded } from "@mui/icons-material"
import { IconButton, Paper, Tooltip, Typography } from "@mui/material"
import { BoxFR } from "components/BoxCustom"
import PopoverDefault from "components/PopoverDefault"

export const advColumns = ({ handleEditAdvance, handleAddItem, handleAddItemUseAll, handleReturnChange }) => [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "UsrNNm",
    headerName: "ผู้รับเงิน",
    width: 70,
    cellClassName: ({ row }) => row.isFromProcessDrvId ? "fromProcessDrvId" : "",
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 80,
    renderCell: ({ value, row }) => value ? (
      <BoxFR sx={{ gap: 0 }}>
        <Typography variant="body2">{value}</Typography>
        {/* <Tooltip title={`${row.TakePlc}-${row.Loc}-${row.RtnPlc}`} placement="top" arrow>
          <InfoRounded color="action" fontSize="small" />
        </Tooltip> */}

        <PopoverDefault icon={<InfoRounded color="action" fontSize="small" />} >
          <Paper sx={{ p: 1 }}>
            <Typography variant="body2">
              {`${row.ContSizeFull} ${row.TakePlc}-${row.Loc}-${row.RtnPlc}`}
            </Typography>
          </Paper>
        </PopoverDefault>
      </BoxFR>
    ) : null
  },
  {
    field: "ExpNm",
    headerName: "รายการ",
    minWidth: 100,
    flex: 1,
    renderCell: ({ value, row }) => (
      <BoxFR sx={{ gap: 0 }}>
        <Typography variant="body2">{value}</Typography>
        {row.Dscp || row.Rmk ?
          <Tooltip title={`${row.Dscp ? `(${row.Dscp})` : ""}${row.Rmk ? `(${row.Rmk})` : ""}`} placement="top" arrow>
            <Typography color="red">*</Typography>
          </Tooltip>
          : null
        }
      </BoxFR>
    )
  },
  {
    field: "AmntRemain",
    headerName: "เงินจ่าย/เหลือ",
    width: 100,
    align: "right",
    valueGetter: ({ row }) => row.PrPCTrnsId ? +row.Amnt : `${+row.Amnt}/${+row.Amnt - +(row.ChdAmnt || 0) - +(row.ItmAmnt || 0)}`,
    valueFormatter: ({ value }) => value.toLocaleString(),
  },
  {
    field: "x1",
    headerName: "ใช้พอดี",
    width: 55,
    align: "center",
    renderCell: ({ row }) => (
      <Tooltip title="ใช้เงินพอดีไม่มีเลขที่ใบเสร็จ" arrow placement="top">
        <IconButton sx={{ p: 0 }} onClick={() => handleAddItemUseAll(row)}>
          <PriceCheck color="primary" />
        </IconButton>
      </Tooltip>
    )
  },
  {
    field: "x2",
    headerName: "ทอน",
    width: 40,
    minWidth: 0,
    align: "center",
    renderCell: ({ row }) => (
      <Tooltip title="เงินเหลือทอนคืน" arrow placement="top">
        <IconButton sx={{ p: 0 }} onClick={() => handleReturnChange(row)}>
          <PublishedWithChangesRounded color="primary" />
        </IconButton>
      </Tooltip>
    )
  },
  {
    field: "edit",
    headerName: "",
    width: 65,
    renderCell: ({ row }) => {
      const isDisableEdit = row.PCPrdId
        || (+(row.ChdAmnt || 0) !== 0)
        || (+(row.ItmAmnt || 0) !== 0)
      return (<BoxFR sx={{ gap: 0 }}>
        <Tooltip title="แก้ไข" arrow placement="top">
          <IconButton sx={{ p: 0 }} onClick={isDisableEdit ? null : () => handleEditAdvance(row)}>
            <EditRounded color={isDisableEdit ? "disabled" : "primary"} />
          </IconButton>
        </Tooltip>
        <Tooltip title="ใส่รายละอียดการใช้เงิน" arrow placement="top">
          <IconButton sx={{ p: 0 }} onClick={() => handleAddItem(row)}>
            <Receipt color="primary" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="ใช้เงินพอดีไม่มีเลขที่ใบเสร็จ" arrow placement="top">
          <IconButton sx={{ p: 0 }} onClick={() => handleAddItemUseAll(row)}>
            <PriceCheck color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="เงินเหลือทอนคืน" arrow placement="top">
          <IconButton sx={{ p: 0 }} onClick={() => handleReturnChange(row)}>
            <PublishedWithChangesRounded color="primary" />
          </IconButton>
        </Tooltip> */}
      </BoxFR>)
    }
  }
]

export const itmColumns = (handleDeleteItem) => [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "UsrNNm",
    headerName: "ผุ้ใช้เงิน",
    width: 80,
  },
  {
    field: "DocNo",
    headerName: "เลขที่เอกสาร",
    width: 90,
  },
  {
    field: "ExpNm",
    headerName: "รายการ",
    minWidth: 150,
    flex: 1,
    renderCell: ({ value, row }) => (
      <BoxFR sx={{ gap: 0 }}>
        <Typography variant="body2">{value}</Typography>
        {row.Dscp || row.Rmk ?
          <Tooltip title={`${row.Dscp ? `(${row.Dscp})` : ""}${row.Rmk ? `(${row.Rmk})` : ""}`} placement="top" arrow>
            <Typography color="red">*</Typography>
          </Tooltip>
          : null
        }
      </BoxFR>
    )
  },
  {
    field: "Amnt",
    headerName: "จำนวนเงิน",
    width: 100,
    align: "right",
    valueFormatter: ({ value }) => (+value).toLocaleString(),
  },
  {
    field: "edit",
    headerName: "",
    width: 40,
    renderCell: ({ row }) => <BoxFR sx={{ gap: 0 }}>
      <Tooltip title="ลบข้อมูล" arrow placement="top">
        <IconButton sx={{ p: 0 }} onClick={row.canRemove ? () => handleDeleteItem(row) : null}>
          <RemoveCircleRounded color={row.canRemove ? "primary" : "disabled"} />
        </IconButton>
      </Tooltip>
    </BoxFR>
  }
]